$yellow: #FBDD3F;

.loose {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    background: black;
    display: flex;
    align-items: center;
    padding-top: 50px;
    padding-bottom: 30px;
    min-height: 100vh; /* Fallback for browsers that do not support Custom Properties */
    min-height: calc(var(--vh, 1vh) * 100);

    @media screen and (min-width: 728px) {
        min-height: 100%;
    }
    box-sizing: border-box;
    justify-content: flex-start;
    color: white;
    font-family: "PriceDownBL";
    text-transform: uppercase;
    font-variant-ligatures: no-common-ligatures;
    color: $yellow;
    font-size: 64px;
    flex-direction: column;

    @media screen and (max-width: 728px) {
        padding-top: 30px;
    }

    img {
        margin-top: 30px;
        max-height: 50vh;
    }

    button {
        background: $yellow;
        font-size: 24px;
        color: black;
        border: none;
        margin-top: 30px;
        font-family: "PriceDownBL";
        text-transform: uppercase;
        border-radius: 30px;
        padding: 10px 40px;
        font-variant-ligatures: no-common-ligatures;
    }

    .panelLoose {
        position: relative;

        .panelWrite {
            position: absolute;
            background: #808080;
            border-radius: 5px;
            font-size: 18px;
            width: 80%;
            margin: auto;
            bottom: 6px;
            padding: 15px 15px;
            box-sizing: border-box;
            left: 50%;
            transform: translateX(-50%);
            
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;

            .row {
                display: flex;
                width: 100%;
                justify-content: space-between;

                @media screen and (max-height: 600px) {
                    flex-direction: column;
                }

                p {
                    width: calc(50% - 4px);
                    border-radius: 3px;
                    background: white;
                    text-align: center;
                    box-sizing: border-box;
                    color: #808080;
                    padding: 5px;

                    @media screen and (max-height: 600px) {
                        width: 100%;
                        margin-bottom: 5px;
                    }
                }
            }

            .name {
                background: #A0A0A0;
                color: white;
                width: 100%;
                text-align: center;
                padding: 20px;
                box-sizing: border-box;
                border-radius: 3px;
                margin-top: 10px;

                @media screen and (max-height: 600px) {
                    margin-top: 0;
                }
            }
        }
    }
}
$yellow: #FBDD3F;

.nameScreen {
    position: absolute;
    top: 0;
    min-height: 100%;
    bottom: 0;
    height: 100vh; /* Fallback for browsers that do not support Custom Properties */
    height: calc(var(--vh, 1vh) * 100);

    @media screen and (min-width: 728px) {
        height: 100%;
    }

    box-sizing: border-box;
    left: 0;
    right: 0;
    background: black;
    z-index: 10;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .center {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    
    p {
        color: white;
        font-family: "PriceDownBL";
        text-transform: uppercase;
        font-variant-ligatures: no-common-ligatures;
        font-size: 38px;

        span {
            color: $yellow;
        }
    }

    input {
        width: 80%;
        border: solid 2px $yellow;
        padding: 10px;
        font-family: "PriceDownBL";
        text-transform: uppercase;
        font-variant-ligatures: no-common-ligatures;
        background-color: rgba($yellow, 0.4);
        border-radius: 5px;
        margin-top: 5%;
        font-size: 21px;
        color: $yellow;

        &::placeholder {
            color: $yellow;
        }
    }

    button {
        border-radius: 50px;
        font-family: "PriceDownBL";
        text-transform: uppercase;
        font-variant-ligatures: no-common-ligatures;
        color: black;
        font-size: 21px;
        background: $yellow;
        border: none;
        width: 200px;
        padding: 10px 20px;
        margin-top: 10%;

        &:disabled {
            background: gray;
        }
    }
}
$yellow: #FBDD3F;
$green: #31CD2D;
$red: #DA1212;

.doorIndication {
    display: flex;
    flex-direction: column;
    align-items: center;

    .textIndication {
        background-color: $yellow;
        border-radius: 5px;
        transition: .3s background-color ease-in-out;
        color: black;
        font-family: "PriceDownBL";
        font-variant-ligatures: no-common-ligatures;
        font-size: 28px;
        padding: 10px 30px;
        margin-top: 0%;
        box-sizing: border-box;
    }

    .silhouetteContainer {
        height: 70px;
        width: 70px;
        background-color: $yellow;
        transition: .3s background-color ease-in-out;
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        position: relative;
        margin-top: 30px;

        img {
            height: 90%;
        }

        &.fingerprintIconContainer{
            border-radius: 50%;
            align-items: center;
            
            img {
                height: 80%;
            }
        }

        &.pressionIconContainer{
            align-items: center;
            
            img {
                height: 80%;
            }
        }

        &::before {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            content: '';
            width: 15px;
            height: 15px;
            border-radius: 50%;
            background-color: $yellow;
            right: -100px;
        }

        &::after {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            content: '';
            width: 15px;
            height: 15px;
            border-radius: 50%;
            background-color: $yellow;
            left: -100px;
        }
    }

    

    &.goodFind {
        .textIndication {
            background-color: $green;
        }

        .silhouetteContainer {
            background-color: $green;
        }
    }

    &.badFind {
        .textIndication {
            background-color: $red;
        }

        .silhouetteContainer {
            background-color: $red;
        }
    }
}



/*triangle background large*/
.triangle-bg-lg, .triangle-bg-lg:before, .triangle-bg-lg:after { width: 25em; height: 25em; }

/*triangle background medium*/
.triangle-bg-md, .triangle-bg-md:before, .triangle-bg-md:after { width: 20em; height: 20em; }

/*triangle background small*/
.triangle-bg-sm, .triangle-bg-sm:before, .triangle-bg-sm:after { width: 15em; height: 15em; }

/*triangle background extra small*/
.triangle-bg-xs, .triangle-bg-xs:before, .triangle-bg-xs:after { width: 10em; height: 10em; }

/*triangle background extra extra small*/
.triangle-bg-xxs, .triangle-bg-xxs:before, .triangle-bg-xxs:after { width: 5em; height: 5em; }

/*common triangle style*/
.triangle-bg-lg,.triangle-bg-md, .triangle-bg-sm,.triangle-bg-xs,.triangle-bg-xxs {
    overflow: hidden;
    position: relative;
    margin:2em auto;
    border-radius: 20%;
    transform: translateY(50%) rotate(30deg) skewY(30deg) scaleX(.866);
} 
.triangle-bg-lg:before, .triangle-bg-lg:after,.triangle-bg-md:before, .triangle-bg-md:after, .triangle-bg-sm:before, .triangle-bg-sm:after,.triangle-bg-xxs:before, .triangle-bg-xxs:after{
    position: absolute;
    background: #ccc;
    pointer-events: auto;
    content: '';
}
.triangle-bg-xs:before, .triangle-bg-xs:after{
    background: #ccc;
    position: absolute;
    pointer-events: auto;
    content: '';
}
.triangle-bg-lg:before, .triangle-bg-md:before, .triangle-bg-sm:before, .triangle-bg-xs:before,.triangle-bg-xxs:before {
    border-radius: 20% 20% 20% 53%;
    transform: scaleX(1.155) skewY(-30deg) rotate(-30deg) translateY(-42.3%) 
            skewX(30deg) scaleY(.866) translateX(-24%);
}
.triangle-bg-lg:after, .triangle-bg-md:after,.triangle-bg-sm:after,.triangle-bg-xs:after,.triangle-bg-xxs:after {
    border-radius: 20% 20% 53% 20%;
    transform: scaleX(1.155) skewY(-30deg) rotate(-30deg) translateY(-42.3%) 
            skewX(-30deg) scaleY(.866) translateX(24%);
}

.triangle {
    position: relative;
    img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate3d(-50%, -50%, 0);
        z-index: 1;
    }
}

.electricalContainer {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
    margin-bottom: 30px;

    .triangle {
        position: absolute;
        top: 0;
    }

    img {
        position: absolute;
        top: 0;
    }

    .electricalBg {
        height: 70px;
        width: auto;
        position: initial;
    }

    .electricalIcon {
        position: absolute;
        top: 50%;
        transform: translateY(calc(-50% + 5px));
        height: 40px;
        z-index: 30;
    }
}
$yellow: #FBDD3F;

.map-container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    overflow: hidden;
    bottom: 0;

    canvas {
        height: 100%;
        width: 100%;
        margin: 0;
        padding: 0;
    }
}

.mapboxgl-control-container {
    display: none;
}

.indicationPosition {
    position: absolute;
    top: 50px;
    width: 80%;
    left: 50%;
    transform: translateX(-50%);
    height: 2px;
    background: white;
    z-index: 10;

  

    .policePoste {
        background: white;
        position: absolute;
        right: -15px;
        box-sizing: border-box;
        top: -15px;
        padding: 5px;
        width: 30px;
        height: 30px;
        background: white;
        border-radius: 50%;
    }

    .cursorPosition {
        position: absolute;
        left: 0;
        width: 15px;
        height: 15px;
        background: yellow;
        top: -7.5px;
        transition: .3s all ease-in-out;
    }

    .indicateur {
        width: 100%;
        transform: translateY(-50%);
    }
}

.findCommissariatPage {

    * {
          
      -webkit-touch-callout: none!important; /* iOS Safari */
      -webkit-user-select: none!important; /* Safari */
       -khtml-user-select: none!important; /* Konqueror HTML */
         -moz-user-select: none!important; /* Old versions of Firefox */
          -ms-user-select: none!important; /* Internet Explorer/Edge */
              user-select: none!important; /* Non-prefixed version, currently */
        }
        
    footer {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        background: black;
        margin: 0;
        box-sizing: border-box;
        padding: 10px;
    }
    
    .helpBtn {
        bottom: 80px;
    }
}

.indicatorArrow {
    width: 15px;
    transform: translateY(-50%);
    position: absolute;
    top: 0;
    transition: .3s all ease-in-out;
    z-index: 10;
    left: 0;
}

.timeLeft {
    position: absolute;
    top: 70px;
    background: $yellow;
    color: black;
    padding: 8px 12px;
    z-index: 8;
    left: 50%;
    transform: translateX(-50%);
    border: solid 2px black;
    font-family: "PriceDownBL";
    text-transform: uppercase;
    font-size: 28px;
    font-variant-ligatures: no-common-ligatures;
    border-radius: 3px;
}
$yellow: #FBDD3F;

.slick-slider{
    margin-bottom: -4px;
    width: 80%;
    margin: auto;
    // overflow: hidden;

    .slick-slide {
        visibility: hidden;

        &.slick-active {
            visibility: initial;
        }
    }



    img {
        max-width: 100%;
        margin: auto;
        width: 200px;
        margin-bottom: -3px;
    }

    .slick-prev {
        left: -25px;
        width: 30px;
        height: 30px;
        
        &:before {
            color: black;
            z-index: 2;
            font-size: 26px;
        }
    }
    

    .slick-next {
        right: -25px;
        width: 30px;
        height: 30px;

        &:before {
            color: black;
            z-index: 2;
            font-size: 26px;
        }
    }

    &:first-of-type {
        .slick-next:before, .slick-prev:before {
            display: none;
        }
    }
}

.fingerprint {
    padding: 30px;
}

.fingerprintContainer {
    margin-top: 30px;
    background: url(../../assets/images/fingerprintBg.png);
    max-width: 90%;
    background-size: contain;
    background-position: center;
    border-radius: 5px;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    position: relative;

}

.enigmePage {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    background: black;
    padding-top: 30px;
    padding-bottom: 30px;
    z-index: 10;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    overflow-y: auto;
    height: 100%;
    box-sizing: border-box;

    * {
          
      -webkit-touch-callout: none!important; /* iOS Safari */
      -webkit-user-select: none!important; /* Safari */
       -khtml-user-select: none!important; /* Konqueror HTML */
         -moz-user-select: none!important; /* Old versions of Firefox */
          -ms-user-select: none!important; /* Internet Explorer/Edge */
              user-select: none!important; /* Non-prefixed version, currently */
        }

    @media screen and (min-width: 728px) {
        padding-top: 50px;
    }

    button.validate {
        background: $yellow;
        border: none;
        color: black;
        padding: 10px 50px;
        border-radius: 100px;
        font-family: "PriceDownBL";
        margin-top: 30px;
        font-size: 24px;
        text-transform: uppercase;
        font-variant-ligatures: no-common-ligatures;
    }

    .scanner {
        width: 100%;
        position: absolute;
        bottom: 0;
        height: 0%;
        background: rgba(255, 45, 45, 0.6);
        left: 0;
        height: 0%;
        box-sizing: border-box;
        transition: 3s height ease-in-out;
        visibility: hidden;

        &.on {
            visibility: initial;
            height: 100%;
        }

        &:before {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 0px;
            content: '';
            box-shadow: 0px 0px 5px 4px rgba(255, 25, 25, 1);
        }
    }
}

.blink_me {
    animation: blinker .3s linear infinite;
    background: rgb(255, 0, 255);
    filter: invert(1);

    .slick-slider {
      .slick-next:before {
          display: none;
      }
      
      .slick-prev:before {
          display: none;
      }
    }
  }
  
  @keyframes blinker {
    50% {
      opacity: 0;
    }
  }

  .smallIndicationIcon {
      margin-top: 30px;
  }

  .loadingText {
      color: $yellow;
      font-size: 48px;
  }
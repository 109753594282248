@media screen and (min-width: 500px) {
    .phone {
        overflow-y: auto;
        overflow-x: hidden;
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        position: relative;
        align-items: center;
        justify-content: space-between;
        align-items: center;
        justify-content: center;
        background-color: black;
        background-position: center;
        background-size: cover;
        position: fixed;
        left: 50%;
        top: 50%;
        transform: translate3d(-50%, -50%, 0);
      
        footer {
          @media screen and (min-width: 728px) {
            display: none;
          }
        }
      
        @media screen and (min-width: 728px) {
          border-radius: 20px;
          position: fixed;
          width: 360px;
          height: 780px;
          max-height: 80%;
          margin-top: -30px;
          margin-bottom: auto;
          box-shadow: 0px 0px 0px 11px #1f1f1f, 0px 0px 0px 13px #191919, 0px 0px 0px 20px #111;
          
          &:before,
          &:after{
            content: '';
            top: 0;
            position: fixed;
            left: 50%;
            transform: translateX(-50%);
          }
          
          // frontal camera/speaker frame
          &:before {
            top: 0px;
            width: 42%;
            height: 30px;
            background-color: #1f1f1f;
            z-index: 15;
            border-radius: 0px 0px 40px 40px;
          }
          
          >i,
          >b,
          >s,
          >span {
            position: fixed;
            display: block;
            color: transparent;
            z-index: 16;
          }
          
          // speaker
          >i {
            top: 0px;
            left: 50%;
            transform: translate(-50%, 6px);
            height: 8px;
            width: 15%;
            background-color: #101010;
            border-radius: 8px;
            box-shadow: inset 0px -3px 3px 0px rgba(256, 256, 256, 0.2);
          }
          
          // camera
         > b {
            left: 10%;
            top: 0px;
            transform: translate(180px, 4px);
            width: 12px;
            height: 12px;
            background-color: #101010;
            border-radius: 12px;
            box-shadow: inset 0px -3px 2px 0px rgba(256, 256, 256, 0.2);
            
            &:after {
              content: '';
              position: fixed;
              background-color: #2d4d76;
              width: 6px;
              height: 6px;
              top: 2px;
              left: 2px;
              top: 3px;
              left: 3px;
              display: block;
              border-radius: 4px;
              box-shadow: inset 0px -2px 2px rgba(0, 0, 0, 0.5);
            }
          }
          
          // time
         > s {
            top: 50px;
            color: #fff;
            text-align: center;
            text-decoration: none;
            width: 100%;
            font-size: 70px;
            font-weight: 100;
            padding-top: 60px;
          }
          
          // action buttons
          > span {
            bottom: 50px;
            width: 40px;
            height: 40px;
            background-color: rgba(0, 0, 0, 0.3);
            border-radius: 50%;
            left: 30px;
            
            & + span {
              left: auto;
              right: 30px;
            }
          }
        } 
      
        @media screen and (max-width: 728px) {
         > i, >s, >b, >span {
            display: none;
          }
        }
      }

    #root {
      min-height: 100vh;
        background: rgb(0, 0,0);
        background: linear-gradient(0deg, rgba(0, 0, 0,1) 0%, rgba(38,38,38,1) 100%);
        background-image: url(./assets/images/desktopbg.jpg);
        background-size: cover;
    }
}

body {
  min-height: 100vh;
  /* mobile viewport bug fix */
  min-height: -webkit-fill-available;
}

html {
  height: -webkit-fill-available;
}
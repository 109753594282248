$yellow: #FBDD3F;

.startingScreenContainer {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    padding-top: 0px;
    min-height: 100%;
    background: black;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    box-sizing: border-box;
    font-family: "PriceDownBL";
    text-transform: uppercase;

    .helpDA {
        margin: 30px auto;
        line-height: 26px;
        text-transform: initial;
    }

    .titles {
        display: flex;
        margin-top: 30px;
        flex-direction: column;
        padding-bottom: 10px;
        border-bottom: solid 2px $yellow;
        border-top: solid 2px $yellow;

        h2:last-of-type {
            font-size: 54px;
            margin-bottom: 10px;
            margin-top: 10px;
        }
    }

    .images {
        position: relative;
        width: 90%;
        margin: 0 auto;
        margin-top: 5%;

        img {
            width: 80%;
        }

        .MapSvr {
            width: 100%;
        }

        .DAProfil {
            width: 60%;
            position: absolute;
            left: 47%;
            transform: translateX(-50%) rotateY(180deg);
            top: 0px;
        }
    }

    h1 {
        font-size: 72px;
        margin-bottom: 10px;
    }

    h2 {
        font-size: 30px;
    }

    h1, h2 {
        color: $yellow;
        text-align: center;
        font-variant-ligatures: no-common-ligatures;
    }

    .helpDA {
        width: 80%;
        font-size: 16px;
        line-height: 38px;
        font-family: "SanFrancisco";
        font-weight: 800;
        margin: 50px auto;
    }

    p {
        color: white;
        line-height: 32px;
        font-size: 21px;
        font-variant-ligatures: no-common-ligatures;
        text-align: center;
        margin-top: 30px;
        margin-bottom: 30px;
    }

    footer p {
        font-size: 10px;
        margin: 0;
        padding: 0;
    }

    footer {
        
        margin-top: 40px;
    }

    .yourTurn {
        color: $yellow;
        font-size: 28px;
        margin-top: 0px;
        margin: 0;
    }

    button {
        border: none;
        background: $yellow;
        color: black;
        font-size: 24px;
        padding: 10px 20px;
        border-radius: 30px;
        font-family: "PriceDownBL";
        text-transform: uppercase;
        font-variant-ligatures: no-common-ligatures;
        margin-bottom: 0%;
        margin-top: 30px;
        margin-bottom: auto;
    }

    .logoScreen {
        display: flex;
        align-items: center;
        justify-content: space-around;

        img {
            width: 150px;
        }   

        h1 {
            color: white;
            font-family: "PriceDownBL";
            text-transform: uppercase;
            font-size: 48px;
            font-variant-ligatures: no-common-ligatures;
        }
    }

    

    .titles {
        @media screen and (max-height: 500px) {
            .h1 {
                font-size: 52px;
            }

            h2 {
                font-size: 25px;
            }

            h2:last-of-type {
                font-size: 30px;
            }
        }
    }
}
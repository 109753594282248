.notification {
    position: fixed;
    top: 10px;
    width: 96%;
    height: auto;
    background: rgba(255, 255, 255, 0.95);
    z-index: 30;
    left: 2%;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-family: "SanFrancisco";
    font-weight: 500;
    transition: 1.2s all ease-in-out;
    transform: translateY(-200%);
    font-size: 18px;
    line-height: 24px;

    &.on {
        transform: translateY(0);
    }
    
    header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 94%;
        color: rgb(100, 100, 100);
        margin: 10px auto;

        .topNotification_left {
            display: flex;
            align-items: flex-end;
        }

        .imessageIcon {
            width: 20px;
            margin-right: 5px;
        }
    }

    main {
        width: 94%;
        margin: 10px auto;

        h1 {
            font-weight: 800;
            margin-bottom: 10px;
        }
    }
}
.helpBtn {
    position: fixed;
    bottom: 30px;
    right: 30px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: solid 2px black;
    background: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: "SanFrancisco";
    font-weight: 800;
    justify-content: center;
    z-index: 50;
    font-size: 21px;
    color: black;
    animation: bounce 2.7s ease infinite;
}

@keyframes bounce{
    from {transform: scale(1);}
    80%  {transform: scale(1);}
    85%  {transform: scale(1.2);}
    90%  {transform: scale(1);}
    95%  {transform: scale(1.2);}
    to   {transform: scale(1);}
}

.textHelp {
    position: absolute;
    width: 90%;
    height: auto;
    z-index: 51;
    border-radius: 5px;
    padding: 30px;
    box-sizing: border-box;
    background: #FBDD3F;
    font-size: 21px;
    line-height: 28px;
    left: 5%;
    top: 50%;
    transform: translateY(-50%);
    border: solid 2px white;
    -webkit-box-shadow: 2px 3px 35px -2px #000000; 
    box-shadow: 2px 3px 35px -2px #000000;

    p {
        padding-top: 30px;
        font-family: "SanFrancisco";
        font-weight: 800;
    }

    img {
        position: absolute;
        top: 20px;
        right: 20px;
        padding: 10px;
    }
}
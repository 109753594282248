$yellow: #FBDD3F;

.winPage {
    background: black;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: "PriceDownBL";
    text-transform: uppercase;
    font-variant-ligatures: no-common-ligatures;
    min-height: 100vh;
    box-sizing: border-box;
        
    &::-webkit-scrollbar-track{
        border:1px gray solid;
        border-radius:10px;
        -webkit-box-shadow:0 0 6px gray inset;
    }

    .linkfireWait {
        width: 80%;
        margin: 30px auto;
        font-family: "SanFrancisco";
        text-transform: initial;
        font-size: 21px;
        line-height: 28px;
        text-align: center;
        font-weight: 800;
    }

    .linkfireLink {
        width: 50%;
        margin-top: 0px;
        margin-bottom: auto;

        img {
            width: 100%;
            margin: 0;
            height: auto;
        }
    }


    ul {
        font-family: "SanFrancisco";

        input  {
            margin-top: 0px!important;
        }
    }

    img {
        height: 220px;
        margin-top: 30px;
        width: auto;
    }

    footer {
        background: black;
    }

    h1 {
        color: $yellow;
        font-size: 32px;
        text-align: center;

        span {
            font-size: 64px;
            display: block;
        }
    }

    p.annonceSoiree {
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        width: 80%;
        margin: 30px auto;
        font-family: "SanFrancisco";
        font-weight: 800;
        text-transform: initial;
        margin-bottom: 0;
    }

    #formulaire {
        margin: 20px auto;
        display: flex;
        flex-direction: column;
        background: black;
        padding-bottom: 0;
        margin-top: 0;
        > div {
            > div {
                position: relative;
                display: flex;
                align-items: flex-start;
                justify-content: center;
                flex-direction: column;

                label {
                    margin-left: 10%;
                    margin: 20px auto 10px 10%;
                }

                input {
                    width: 80%;
                    margin: 0 auto;
                    border: solid 2px $yellow;
                    background: rgba($yellow, 0.3);
                    padding: 10px;
                    color: $yellow;
                }
            }

            p {
                width: 90%;
                margin: 20px auto   ;
            }

            a {
                color: white;
            }
        }

        ul {
            margin: 30px auto;
            width: 90%;

            label {
                margin-left: 10px;
                text-transform: initial;
            }
        }

        input[type="date"] {
            -webkit-appearance: none;
            padding: 10px;
        }
    }

    .opt-in-button {
        button  {
            margin: 20px auto;
            background: $yellow;
            border: none;
            color: black;
            padding: 10px 50px;
            border-radius: 50px;
            font-family: "PriceDownBL";
            text-transform: uppercase;
            font-variant-ligatures: no-common-ligatures;
            font-size: 21px;
        }
    }

    .linkfire{
        margin: 50px auto;
    }

    .iosIndications {
        font-family: "SanFrancisco";
        font-weight: 800;
        width: 80%;
        margin: auto;
        text-align: center;
        font-size: 14px;
        margin-bottom: 30px;
        color: $yellow;
        text-transform: initial;

        @supports (-webkit-touch-callout: none) {
            /* CSS specific to iOS devices */ 
          }
          
          @supports not (-webkit-touch-callout: none) {
            /* CSS for other than iOS devices */ 
            display: none;
          }
    } 
}
footer {
    color: white;
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    text-align: center;
    font-family: "SanFrancisco";
    font-size: 10px;
    align-items: center;
    display: flex;
    flex-direction: column;

    ul {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
        width: 100%;
        list-style: none;
    }

    p {
        font-size: 10px;
    }

    a {
        text-align: center;
        color: white;
    }

    li {
        list-style: "";
    }

    @media screen and (min-width: 728px) {
        width: 400px;
        font-size: 14px;
        margin-top: auto;

        p {
            font-size: 14px;
        }
    }
}
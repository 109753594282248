.facetimeCall {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    pointer-events: none;
    visibility: hidden;

    &.on {
        pointer-events: all;
        visibility: initial;
    }

    video {
        height: 100%;
        object-fit: cover;
        width: 100%;
    }

    .bottom {
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 150px;
        z-index: 1000;
        border-top-left-radius: 30px;
        border-top-right-radius: 30px;
        background: rgba(255, 255, 255, 0.9);
        backdrop-filter: blur(10px);
        padding: auto 30px;
        display: flex;
        align-items: center;

        &:before {
            position: absolute;
            content: '';
            width: 60px;
            height: 4px;
            border-radius: 5px;
            background: white;
            left: 50%;
            top: 10px;
            transform: translateX(-50%);
        }

        @supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
            background-color: rgba(255, 255, 255, .5);
            -webkit-backdrop-filter: blur(2em);
            backdrop-filter: blur(2em);
        }

        div {
            width: 80%;
            margin: auto;
            display: flex;
            justify-content: space-around;
            align-items: center;

            img {
                width: 20%;
                display: flex;
                align-items: center;
                flex-direction: column;
            }
        }
    }
}
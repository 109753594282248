.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@font-face {
  font-family: 'SanFrancisco';
  font-weight: 500;
  src: local('SanFrancisco'), url(./assets/fonts/system_san_francisco_display_regular-webfont.woff) format('woff');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

@font-face {
  font-family: 'SanFrancisco';
  font-weight: 400;
  src: local('SanFrancisco'), url(./assets/fonts/system_san_francisco_display_thin-webfont.woff) format('woff');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

@font-face {
  font-family: 'SanFrancisco';
  font-weight: 800;
  src: local('SanFrancisco'), url(./assets/fonts/system_san_francisco_display_bold-webfont.woff) format('woff');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}


@font-face {
  font-family: 'PriceDownBL';
  src: local('PriceDownBL'), url(./assets/fonts/pricedown_bl.otf) format('opentype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

#root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: auto;
  background: black;
  width: 100vw;

  @media screen and (min-width: 728px) {
    width: 100%;
  }

  @media screen and (max-width: 728px) {
    b, i {
      display: none;
    }
  }
}

#root {
  height: 100vh;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: "SanFrancisco";

  h1 {
    font-size: 32px;
    font-weight: 800;
    width: 80%;
    margin: 0 auto;
    color: #FBDD3F;
    margin-top: auto;
    margin-bottom: 30px;
    text-align: center;
  }

  footer {
    margin-top: auto;
    margin-bottom: 30px;
  }
}
.introduction {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    min-height: 100%;
    box-sizing: border-box;
    background-color: black;

    img {
        width: 100%;
        height: calc(100% - 92px);
        object-fit: cover;
        opacity: 1;
        object-position: bottom center;
        transition: 1.2s all ease-in-out;
    }

    footer {
        padding: 10px;
        
    }

    &.off {
        img {
            opacity: 0;
        }
    }
}
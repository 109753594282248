.call {
    position: relative;
    width: 100%;
    box-sizing: content-box;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url(../../assets/images/background_call.jpg);
    background-position: center;
    background-size: cover;
    backdrop-filter: blur(10px);
    color: white;
    font-weight: 500;
    font-family: "SanFrancisco";

    padding-top: 30px;

    @media screen and (min-width: 728px) {
        padding-top: 50px;
    }

    display: flex;
    flex-direction: column;
    align-items: center;

    .call_top_infos {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 30px;


        h1 {
            font-size: 32px;
            margin-bottom: 15px;
        }

        p {
            font-size: 18px;

            @media screen and (max-height: 490px) {
                font-size: 14px;
            }
        }
    }

    .respond_btns {
        display: flex;
        justify-content: space-between;
        width: 80%;
        font-weight: 400;
        letter-spacing: 1px;
        font-size: 12px;
        margin: 0 auto;
        margin-bottom: 20%;

        > div {
            display: flex;
            flex-direction: column;
            align-items: center;
        }
    }

    // Main Btns
    .main_btn {
        width: 90px;
        height: 90px;
        border-radius: 50%;
        border: none;
        margin-bottom: 20px;

        @media screen and (max-height: 490px) {
            width: 50px;
            height: 50px;
        }
    }

    .decline {
        background: #FD3C30;
        position: relative;

        &:before {
            content: url(../../assets/icons/call.svg);
            width: 90px;
            height: 90px;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate3d(-50%, -50%, 0) rotate(45deg);

            @media screen and (max-height: 490px) {
                width: 50px;
                height: 50px;
            }
        }
    }

    .accept {
        background: #4CD963;
        position: relative;

        &:before {
            content: url(../../assets/icons/call.svg);
            width: 90px;
            height: 90px;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate3d(-50%, -50%, 0);

            @media screen and (max-height: 490px) {
                width: 50px;
                height: 50px;
            }
        }
    }

    // Secondary Btns
    .secondary_btn {
        border: none;
        background: none;
        color: white;
        margin-bottom: 30px;

        img {
            height: 70px;
        }
    }

    .onCall_secondary {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(2, 1fr);
        grid-row-gap: 60px;
        width: 80%;
        margin-top: auto;
        margin-bottom: auto;

        div {
            width: 90px;
            border-radius: 50%;
            height: 70px;
            margin: auto;

            @media screen and (max-height: 490px) {
                width: 50px;
                height: 50px;
            }

            img {
                width: 100%;
            }

            p {
                margin-top: 5px;
                text-align: center;
            }
        }
    }

    &:not(.first) {
        .decline {
            margin-top: 30%;
        }

        .main_btn {
            margin-bottom: 50px;
        }

        .call_top_infos {
        }
    }

    &.first {
        justify-content: space-between;

        .main_btn {
            width: 70px;
        }
    }
}
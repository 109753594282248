$yellow: #FBDD3F;

@mixin on-circle($item-count, $circle-size, $item-size) {
    position: relative;
    width:  $circle-size;
    height: $circle-size;
    padding: 0;
    border-radius: 50%; 
    list-style: none;       

  

    * {
          
      -webkit-touch-callout: none!important; /* iOS Safari */
      -webkit-user-select: none!important; /* Safari */
       -khtml-user-select: none!important; /* Konqueror HTML */
         -moz-user-select: none!important; /* Old versions of Firefox */
          -ms-user-select: none!important; /* Internet Explorer/Edge */
              user-select: none!important; /* Non-prefixed version, currently */
        }
    
    > * {
      display: block;
      position: absolute;
      top:  50%; 
      left: 50%;
      width:  $item-size;
      height: $item-size;
      margin: -($item-size / 2);
    
      $angle: (360 / $item-count);
      $rot: 0;
  
      @for $i from 1 through $item-count {
        &:nth-of-type(#{$i}) {
          transform: 
            rotate($rot * 1deg) 
            translate($circle-size / 2) 
            rotate($rot * -1deg);
        }
  
        $rot: $rot + $angle;
      }
    }
  }

.checkPlanques {
    footer {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        background: black;
        margin: 0;
        box-sizing: border-box;
        justify-content: center;
    }
}

.distance {
    position: absolute;
    z-index: 21;
    color: $yellow;
    left: 20px;
    width: 120px;
    font-size: 28px;
    font-family: "SanFrancisco";
    font-weight: 800;
    text-shadow: black 1px 0 10px;
    text-align: center;
    bottom: 170px;

    @media screen and (min-width: 500px) {
      bottom: 120px;
    }
}

.boussole {
    position: absolute;
    bottom: 80px;
    background: $yellow;
    z-index: 40;
    left: 30px;
    height: 80px;
    width: 80px;
    border-radius: 50%;

    @media screen and (min-width: 500px) {
      bottom: 30px;
    }

    img {
        position: absolute;
        top: 50%;
        width: 30px;
        left: 50%;
        transform: translate3d(-50%, -50%, 0);
    }

    .interiorBoussole {
        width: 59px;
        height: 59px;
        border: solid 2px black;
        border-radius: 50%;
        position: absolute;

        @include on-circle(1, 60px, 30px);

        // &:after {
            
        //     content: '';
        //     position: absolute;
        //     height: 12px;
        //     width: 12px;
        //     background: black;
        //     border-radius: 50%;
        //     border: solid 2px $yellow;
        //     top: 0;
        //     left: 0;
        // }
    }
}


.smallIndicationIcon {
  margin-top: 30px;
}
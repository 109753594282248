$yellow: #FBDD3F;

.mainIndicationContainer {
    height: 180px;
    position: absolute;
    bottom: 0;
    width: 100%;
}

.indicationContainer {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 10;
    background: rgba(white, 0.3);

    .indication {
        position: absolute;
        bottom: 0;
        width: 100%;
        height: auto;
        z-index: 10;
        background: $yellow;
        box-sizing: border-box;
        display: flex;
        flex-grow: 1;
        align-items: center;
        justify-content: space-between;
        background: black;
        color: white;
        font-family: "PriceDownBL";
        text-transform: uppercase;
        font-variant-ligatures: no-common-ligatures;
        height: auto;
        flex-direction: column;

        .silhouetteContainer {
            background: black;
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            align-items: center;
            position: relative;
            padding-top: 30px;
            height: 10px;
            position: relative;

            p {
                color: $yellow;
                margin-top: 20px;
                font-size: 32px;
                padding-bottom: 0;
                margin-left: 00px;
            }

            img {
                position: absolute;
                left: 10px;
                top: -50px;
                height: 100px;
                width: auto;
                padding: 0 10px;
            }
    
    
            button {
                position: absolute;
                bottom: 0;
                border: none;
                color: black;
                background: $yellow;
                font-size: 27px;
                line-height: 21px;
                right: 0px;
                top: 0;
                width: 40px;
                height: 40px;
                display: flex;
                justify-content: center;
                align-items: center;

                img {
                    width: 15px;
                    padding: 0;
                    position: initial;
                    height: auto;
                }
            }
        }

        p { 
            padding:  20px 0;
            line-height: 30px;
            font-size: 21px;
            display: block;
        }
    }

    .text {
        width: 90%;
        margin: auto;
        font-family: "SanFrancisco";
        font-weight: 800;
        text-transform: initial;
    }
}

.pageIndication {

    * {
          
      -webkit-touch-callout: none!important; /* iOS Safari */
      -webkit-user-select: none!important; /* Safari */
       -khtml-user-select: none!important; /* Konqueror HTML */
         -moz-user-select: none!important; /* Old versions of Firefox */
          -ms-user-select: none!important; /* Internet Explorer/Edge */
              user-select: none!important; /* Non-prefixed version, currently */
        }
        
    .timeLeft {
        top: 50px;
    }

    footer {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        background: black;
        margin: 0;
        box-sizing: border-box;
        padding: 10px;
    }
}
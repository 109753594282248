$yellow: #FBDD3F;
$red: #DA1212;

.pressionContainer {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 10;
    background: black;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 30px;
    padding-bottom: 30px;
    min-height: 100%;
    box-sizing: border-box;

    * {
        -webkit-touch-callout: none; /* iOS Safari */
            -webkit-user-select: none; /* Safari */
            -khtml-user-select: none; /* Konqueror HTML */
            -moz-user-select: none; /* Old versions of Firefox */
                -ms-user-select: none; /* Internet Explorer/Edge */
                    user-select: none; /* Non-prefixed version, currently */
    }

    @media screen and (min-width: 728px) {
        padding-top: 50px;
    }

    .pression {
        border: solid 1px black;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 90%;
        margin-top: 30px;
        box-sizing: border-box;
        background: $yellow;
        padding: 20px;
        padding-bottom: 170px;
        position: relative;
        border-radius: 5px;

        &:after {
            position: absolute;
            bottom: 40px;
            width: 100%;
            left: 0;
            background: rgb(0, 0, 0, 0.4);
            height: 80px;
            content: '';
        }

        .outil {
            position: absolute;
            width: 400px;
            left: calc(10% - 400px);
            bottom: 65px;
            transition: .8s all ease-in-out;
            z-index: 10;
        }

        .barContainer {
            height: 150px;
            width: 20px;
            position: relative;
            background: black;
            border-radius: 3px;

            .bar {
                position: absolute;
                bottom: 0;
                height: 0px;
                background: none;
                width: 30px;
                left: -5px;
                transform-origin: center bottom;

                &:before {
                    position: absolute;
                    top: 0;
                    z-index: 3;
                    left: 0;
                    border-radius: 2px;
                    width: 100%;
                    background: white;
                    height: 10px;
                    content: '';
                }

                &:after {
                    content: '';
                    position: absolute;
                    background-image: url(../../assets/images/ressort.svg);
                    background-size: cover;
                    bottom: -50px;
                    left: 0;
                    height: 40px;
                    width: 100%;
                }
            }

            .limit {
                position: absolute;
                bottom: 60%;
                height: 20px;
                background: $red;
                width: 100%;
                left: 0;
                z-index: 2;
            }
        }
    }

    .pressionBtn { 
        height: 100px;
        width: 100px;
        border-radius: 50%;
        background: $yellow;
        margin-top: 30px;
        border: none;
        color: black;
        font-size: 21px;
        -webkit-user-select: none !important;
        font-family: "PriceDownBL";
        text-transform: uppercase;
        font-variant-ligatures: no-common-ligatures;
    }
}

body { -webkit-touch-callout: none !important; }

.pressionBtn.on {
  /* Start the shake animation and make the animation last for 0.5 seconds */
  animation: shake 0.5s;

  /* When the animation is finished, start again */
  animation-iteration-count: infinite; 
}

@keyframes shake {
    0% { transform: scale(.9); }
    100% { transform: scale(1.1) }
  }

$yellow: #FBDD3F;

.transitionScreen {
    background: black;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;

    .logoSound {
        width: 100%;
        height: 100%;
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        opacity: 0;
        transition: 2s all ease-in-out;

        &.on {
            opacity: 1;
        }
    }

    .logoScreen {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 70%;
        margin: auto;
        height: 100%;
        justify-content: space-between;
        opacity: 0;
        transition: 2s all ease-in-out;

        img {
            height: 100px;
            width: auto;
    
            &.logoV2V {
                height: 200px;
                width: auto;
            }
        }

        &.on {
            opacity: 1;
        }

        img {
            width: auto;
        }
    }

    .volumeIcon {
        width: 250px;
    }

    p {
        color: white;
        font-family: "PriceDownBL";
        text-transform: uppercase;
        font-variant-ligatures: no-common-ligatures;
        text-align: center;
        margin: 0 auto;
        margin-top: 50px;
        font-size: 32px;
        line-height: 42px;


        span {
            color: $yellow;
            display: block; 
        }
    }
}
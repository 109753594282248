$yellow: #FBDD3F;
$green: #31CD2D;
$red: #DA1212;

.filsContainer {
    position: absolute;
    z-index: 10;
    top: 0;
    left: 0;
    right: 0;
    padding-bottom: 30px;
    background: black;
    padding-top: 30px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    min-height: 100%;
    box-sizing: border-box;

    @media screen and (min-width: 728px) {
        padding-top: 50px;
    }

  

    * {
          
      -webkit-touch-callout: none!important; /* iOS Safari */
      -webkit-user-select: none!important; /* Safari */
       -khtml-user-select: none!important; /* Konqueror HTML */
         -moz-user-select: none!important; /* Old versions of Firefox */
          -ms-user-select: none!important; /* Internet Explorer/Edge */
              user-select: none!important; /* Non-prefixed version, currently */
        }


    .fils {
        width: 90%;
        border: solid 2px black;
        border-radius: 5px;
        margin: 0px auto 30px;
        background: #C6BE97;
        background-image: linear-gradient(rgba(255, 255, 255, .5) .2em, transparent .2em), linear-gradient(90deg, rgba(255, 255, 255, .5) .2em, transparent .2em);
        background-size: 2.5em 2.5em;
        img {
            width: 50%;
        }
    }

    // .fil {
    //     display: flex;
    // }

    .filHandler {
        border-radius: 5px;
        width: 90%;
        background-color: $yellow;
        display: flex;
        flex-direction: column;

        p {
            background: #C6BE97;
            color: #000;
            border-radius: 5px;
            border: solid 2px black;
            padding: 10px 50px;
            font-size: 32px;
            display: inline-block;
            font-family: "PriceDownBL";
            text-transform: uppercase;
            font-variant-ligatures: no-common-ligatures;
            margin: 30px auto;
            text-align: center;
        }
    }

    button.validate {
        background: $yellow;
        border: none;
        padding: 10px 50px;
        border-radius: 100px;
        font-family: "PriceDownBL";
        text-transform: uppercase;
        margin: auto;
        margin-top: 30px;
        color: black;
        font-size: 24px;
        font-variant-ligatures: no-common-ligatures;
    }
}